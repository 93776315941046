import theme from './theme';
import { LocalStorage } from 'utils';
import { STORAGE_KEY_ENUM } from 'enums';
import { useEffect, useMemo, useState } from 'react';
import { PaletteMode, createTheme } from '@mui/material';

const useColorTheme = (defaultThemeMode: PaletteMode) => {
  const [mode, setMode] = useState(defaultThemeMode);

  const getDefaultThemeMode = () => {
    const themeModeFromStorage = LocalStorage.getItem(STORAGE_KEY_ENUM.THEME_MODE);
    return themeModeFromStorage ? (themeModeFromStorage as PaletteMode) : defaultThemeMode;
  };

  const setDefaultThemeMode = (themeMode: PaletteMode) => {
    LocalStorage.setItem(STORAGE_KEY_ENUM.THEME_MODE, themeMode);
    setMode(themeMode);
  };

  const toggleColorMode = () => {
    const newThemeMode = mode === 'light' ? 'dark' : 'light';

    setDefaultThemeMode(newThemeMode);
  };

  const init = () => {
    const themeMode = getDefaultThemeMode();
    setDefaultThemeMode(themeMode);
  };

  const modifiedTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    init();
  }, []);

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};

export default useColorTheme;
