import { ReactNode, useEffect } from 'react';
import { SnackbarProvider as Provider, useSnackbar } from 'notistack';

const SnackBarListener = () => {
  const { enqueueSnackbar } = useSnackbar();

  const pushErrorToSnackBar = (error: any) => enqueueSnackbar(error.detail, { variant: 'error' });

  const addEvent = () => window.addEventListener('errorEvent', pushErrorToSnackBar);
  const removeEvent = () => window.removeEventListener('errorEvent', pushErrorToSnackBar);

  useEffect(() => {
    addEvent();

    return () => removeEvent();
  }, []);

  return <></>;
};

type SnackBarProviderProps = {
  children: ReactNode;
};

const SnackBarProvider = ({ children }: SnackBarProviderProps) => (
  <Provider>
    <SnackBarListener />
    {children}
  </Provider>
);

export default SnackBarProvider;
