import { jwtDecode } from 'jwt-decode';

class Token {
  getDecoded(token: string) {
    return jwtDecode(token);
  }

  isExpired(token: string) {
    const { exp } = this.getDecoded(token);
    const expirationDate = (exp as number) * 1000;
    const now = new Date().getTime();

    return now > expirationDate;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Token();
