import { Authentication } from 'utils';
import { ROLE_TYPE_ENUM } from 'enums';
import { FormattedMessage } from 'react-intl';
import GroupIcon from '@mui/icons-material/Group';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { Box, List, Drawer, Toolbar, ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';

const drawerWidth = 240;

export type SideBarItem = {
  name: JSX.Element;
  route: string;
  icon: JSX.Element;
  permissions: ROLE_TYPE_ENUM[];
};

export const sideBarItems: SideBarItem[] = [
  {
    name: <FormattedMessage id="sidebar.dashboard" defaultMessage="Genel Bakış" />,
    route: 'dashboard',
    icon: <DashboardIcon />,
    permissions: [ROLE_TYPE_ENUM.CREATER, ROLE_TYPE_ENUM.WRITER, ROLE_TYPE_ENUM.VIEWER, ROLE_TYPE_ENUM.APPROVER],
  },
  {
    name: <FormattedMessage id="sidebar.createProject" defaultMessage="Proje Oluştur" />,
    route: 'project',
    icon: <PlaylistAddIcon />,
    permissions: [ROLE_TYPE_ENUM.CREATER],
  },
  {
    name: <FormattedMessage id="sidebar.users" defaultMessage="Kullanıcılar" />,
    route: 'users',
    icon: <GroupIcon />,
    permissions: [ROLE_TYPE_ENUM.CREATER],
  },
  {
    name: <FormattedMessage id="sidebar.timeline" defaultMessage="Tarihçe" />,
    route: 'timeline',
    icon: <TimelineOutlinedIcon />,
    permissions: [ROLE_TYPE_ENUM.CREATER],
  },
];

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const loggedUser = Authentication.getLoggedUser();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}>
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {sideBarItems.map((sideBarItem, index) => {
            const hasPermission = sideBarItem.permissions.some((permission) => permission === loggedUser.role);

            return hasPermission ? (
              <ListItem key={index.toString()} disablePadding onClick={() => navigate(sideBarItem.route)}>
                <ListItemButton selected={pathname === `/${sideBarItem.route}`}>
                  <ListItemIcon sx={{ color: pathname === `/${sideBarItem.route}` ? 'primary.main' : '' }}>
                    {sideBarItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={sideBarItem.name} />
                </ListItemButton>
              </ListItem>
            ) : null;
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
