import { useState } from 'react';
import { Authentication } from 'utils';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AccountCircle } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogOutHandler = () => Authentication.logOut();

  return (
    <>
      <Tooltip title={<FormattedMessage id="profile.profile" defaultMessage="Profil" />}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'profile-picker' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <AccountCircle fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="profile-picker"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={handleClose}>
          <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/profile`}>
            <FormattedMessage id="profile.myProfile" defaultMessage="Profilim" />
          </Link>
        </MenuItem>
        <MenuItem onClick={onClickLogOutHandler}>
          <FormattedMessage id="profile.signOut" defaultMessage="Çıkış Yap" />
        </MenuItem>
      </Menu>
    </>
  );
};

export default Profile;
