import useColorTheme from './use-color-theme';
import { ReactNode, createContext, useContext } from 'react';
import { PaletteMode, Theme, createTheme, ThemeProvider as Provider } from '@mui/material';

const defaultThemeMode: PaletteMode = 'light';

type ThemeContextProps = {
  mode: PaletteMode;
  toggleColorMode: () => void;
  theme: Theme;
};

export const ThemeContext = createContext<ThemeContextProps>({
  mode: defaultThemeMode,
  toggleColorMode: () => {},
  theme: createTheme(),
});

type ThemeProviderProps = {
  children: ReactNode;
};

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const value = useColorTheme(defaultThemeMode);

  return (
    <ThemeContext.Provider value={value}>
      <Provider theme={value.theme}>{children}</Provider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;

export const useThemeContext = () => useContext(ThemeContext);
