import { Authentication } from 'utils';
import { useSnackbar } from 'notistack';
import { Project } from 'pages/dashboard';
import apiService from 'library/api-service';
import { DatePicker } from '@mui/x-date-pickers';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { sideBarItems } from 'components/layout/sidebar';
import loaderLogoSrc from 'assets/idecon-loader-logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Button, CircularProgress, Grid, TextField } from '@mui/material';

export type ProjectToBeCreated = Omit<Project, 'id' | 'companyId'>;

const initialState: ProjectToBeCreated = {
  companyName: '',
  project: '',
  connectType: '',
  gibType: '',
  usedBanks: '',
  liveDate: null,
  archiveLiveDate: null,
  companyCode: '',
  vkn: '',
  apiInformation: '',
  serviceApiInformation: '',
  rfcUserInformation: '',
  vpnInformation: '',
  serverConnectionInformation: '',
  serverExternalIp: '',
  financialSeal: '',
  sapDev: '',
  sapTest: '',
  sapProd: '',
  todo: '',
  prodRequest: '',
  companyTablePassword: '',
  apiVersion: '',
  newDespatchPackage: '',
};

const CreateProject = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const loggedUser = Authentication.getLoggedUser();
  const goToMainPage = useCallback(() => navigate('/'), []);

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectToBeCreated>({
    defaultValues: initialState,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  // async helper functions
  const onSubmit = async (data: ProjectToBeCreated) => {
    // Empty Company Name control
    if (!data.companyName)
      return enqueueSnackbar(
        <FormattedMessage id="project.requiredCompanyCode" defaultMessage="Firma boş bırakılamaz!" />,
        {
          variant: 'error',
        }
      );

    // Convert empty string values to null & dayjs values to ISO format
    const realData: ProjectToBeCreated = Object.entries(data).reduce((acc, arrEl) => {
      if (!arrEl[1]) return { ...acc, [arrEl[0]]: null };

      if ((arrEl[1] as any).$isDayjsObject) return { ...acc, [arrEl[0]]: (arrEl[1] as any).toISOString() };

      return { ...acc, [arrEl[0]]: arrEl[1] };
    }, initialState);

    // continue
    setLoading(true);

    try {
      const response = await apiService.createProject(realData);

      if (response.isSuccess) {
        enqueueSnackbar(
          <FormattedMessage id="project.createSuccess" defaultMessage="Proje başarılı bir şekilde oluşturuldu." />,
          {
            variant: 'success',
          }
        );

        goToMainPage();
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  // sync helper functions
  const onPressTabKeyHandler = (e: any) => {
    const { value } = e.target;

    if (e.key === 'Tab') {
      e.preventDefault();

      const cursorPosition = e.target.selectionStart;
      const cursorEndPosition = e.target.selectionEnd;
      const tab = '\t';

      const lastValue = value.substring(0, cursorPosition) + tab + value.substring(cursorEndPosition);

      e.target.value = lastValue;
      setValue(e.target.id, lastValue);

      // if you modify the value programmatically, the cursor is moved
      // to the end of the value, we need to reset it to the correct
      // position again
      e.target.selectionStart = cursorPosition + 1;
      e.target.selectionEnd = cursorPosition + 1;
    }
  };

  useEffect(() => {
    const sidebarItem = sideBarItems.find((sidebarItem) => sidebarItem.route === pathname.slice(1));
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser.role);

    if (!hasPermission) return goToMainPage();
  }, []);

  return (
    <Grid container direction="column" gap={3}>
      <Grid item xs={12}>
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.companyName', defaultMessage: 'Firma' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.companyName}
              helperText={errors.companyName?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.project', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.project}
              helperText={errors.project?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="connectType"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.connectType', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.connectType}
              helperText={errors.connectType?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="gibType"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.gibType', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.gibType}
              helperText={errors.gibType?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="usedBanks"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.usedBanks', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.usedBanks}
              helperText={errors.usedBanks?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="liveDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              label={intl.formatMessage({ id: 'project.liveDate', defaultMessage: 'Ürünler' })}
              localeText={{
                previousMonth: intl.formatMessage({ id: 'dashboard.previousMonth', defaultMessage: 'Önceki Ay' }),
                nextMonth: intl.formatMessage({ id: 'dashboard.nextMonth', defaultMessage: 'Sonraki Ay' }),

                // for Mobile
                okButtonLabel: intl.formatMessage({ id: 'dashboard.okButtonLabel', defaultMessage: 'Tamam' }),
                cancelButtonLabel: intl.formatMessage({
                  id: 'dashboard.cancelButtonLabel',
                  defaultMessage: 'Vazgeç',
                }),
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  variant: 'outlined',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="archiveLiveDate"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              label={intl.formatMessage({ id: 'project.archiveLiveDate', defaultMessage: 'Ürünler' })}
              localeText={{
                previousMonth: intl.formatMessage({ id: 'dashboard.previousMonth', defaultMessage: 'Önceki Ay' }),
                nextMonth: intl.formatMessage({ id: 'dashboard.nextMonth', defaultMessage: 'Sonraki Ay' }),

                // for Mobile
                okButtonLabel: intl.formatMessage({ id: 'dashboard.okButtonLabel', defaultMessage: 'Tamam' }),
                cancelButtonLabel: intl.formatMessage({
                  id: 'dashboard.cancelButtonLabel',
                  defaultMessage: 'Vazgeç',
                }),
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  variant: 'outlined',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="companyCode"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.companyCode', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.companyCode}
              helperText={errors.companyCode?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="vkn"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.vkn', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.vkn}
              helperText={errors.vkn?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="apiInformation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.apiInformation', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.apiInformation}
              helperText={errors.apiInformation?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="serviceApiInformation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.serviceApiInformation', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.serviceApiInformation}
              helperText={errors.serviceApiInformation?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="rfcUserInformation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.rfcUserInformation', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.rfcUserInformation}
              helperText={errors.rfcUserInformation?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="vpnInformation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.vpnInformation', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.vpnInformation}
              helperText={errors.vpnInformation?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="serverConnectionInformation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.serverConnectionInformation', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.serverConnectionInformation}
              helperText={errors.serverConnectionInformation?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="serverExternalIp"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.serverExternalIp', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.serverExternalIp}
              helperText={errors.serverExternalIp?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="financialSeal"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.financialSeal', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.financialSeal}
              helperText={errors.financialSeal?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="sapDev"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.sapDev', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.sapDev}
              helperText={errors.sapDev?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="sapTest"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.sapTest', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.sapTest}
              helperText={errors.sapTest?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="sapProd"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.sapProd', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.sapProd}
              helperText={errors.sapProd?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="todo"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.todo', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.todo}
              helperText={errors.todo?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="prodRequest"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.prodRequest', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.prodRequest}
              helperText={errors.prodRequest?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="companyTablePassword"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.companyTablePassword', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.companyTablePassword}
              helperText={errors.companyTablePassword?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="apiVersion"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.apiVersion', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.apiVersion}
              helperText={errors.apiVersion?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="newDespatchPackage"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label={intl.formatMessage({ id: 'project.newDespatchPackage', defaultMessage: 'Ürünler' })}
              variant="outlined"
              fullWidth
              size="small"
              onKeyDown={onPressTabKeyHandler}
              multiline
              error={!!errors.newDespatchPackage}
              helperText={errors.newDespatchPackage?.message}
            />
          )}
        />
      </Grid>
      <Grid container direction="row" item gap={2}>
        <Grid item>
          <Button onClick={handleSubmit(onSubmit)} variant="contained" sx={{ mb: 2 }}>
            <FormattedMessage id="project.create" defaultMessage="Oluştur" />
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => reset()} variant="contained" color="secondary" sx={{ mb: 2 }}>
            <FormattedMessage id="project.reset" defaultMessage="Temizle" />
          </Button>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <img src={loaderLogoSrc} alt="loader-logo" />
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default CreateProject;
