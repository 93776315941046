import {
  Grid,
  Button,
  Dialog,
  Divider,
  TextField,
  InputLabel,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import { ROLE_TYPE_ENUM } from 'enums';
import { useSnackbar } from 'notistack';
import { ShortProject } from 'library/types';
import apiService from 'library/api-service';
import { DatePicker } from '@mui/x-date-pickers';
import { FormattedMessage, useIntl } from 'react-intl';
import { Authentication, renderFormattedDate } from 'utils';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';

export type Project = {
  id: number;
  companyId: number;
  companyName: string | null;
  project: string | null;
  connectType: string | null;
  gibType: string | null;
  usedBanks: string | null;
  liveDate: string | null;
  archiveLiveDate: string | null;
  companyCode: string | null;
  vkn: string | null;
  apiInformation: string | null;
  serviceApiInformation: string | null;
  rfcUserInformation: string | null;
  vpnInformation: string | null;
  serverConnectionInformation: string | null;
  serverExternalIp: string | null;
  financialSeal: string | null;
  sapDev: string | null;
  sapTest: string | null;
  sapProd: string | null;
  todo: string | null;
  prodRequest: string | null;
  companyTablePassword: string | null;
  apiVersion: string | null;
  newDespatchPackage: string | null;
};

export type ProjectToBeEdited = Omit<Project, 'companyName' | 'companyId'>;

const initialState: Project = {
  id: 9999,
  companyId: 9999,
  companyName: null,
  project: null,
  connectType: null,
  gibType: null,
  usedBanks: null,
  liveDate: null,
  archiveLiveDate: null,
  companyCode: null,
  vkn: null,
  apiInformation: null,
  serviceApiInformation: null,
  rfcUserInformation: null,
  vpnInformation: null,
  serverConnectionInformation: null,
  serverExternalIp: null,
  financialSeal: null,
  sapDev: null,
  sapTest: null,
  sapProd: null,
  todo: null,
  prodRequest: null,
  companyTablePassword: null,
  apiVersion: null,
  newDespatchPackage: null,
};

const Dashboard = () => {
  // other hooks
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const loggedUser = Authentication.getLoggedUser();

  // useState hooks
  const [edit, setEdit] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [projects, setProjects] = useState<ShortProject[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [editFormParams, setEditFormParams] = useState(initialState);
  const [selectedProject, setSelectedProject] = useState<Project>(initialState);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(null);

  // async helper functions
  const getProjects = async () => {
    setLoadingProjects(true);

    try {
      const response = await apiService.getProjects();
      setProjects(response.data);
    } catch (error) {
    } finally {
      setLoadingProjects(false);
    }
  };

  const getSpecificProject = async (companyId: number) => {
    try {
      const response = await apiService.getSpecificProject(companyId);
      setSelectedProject(response.data);
      setEditFormParams(response.data);
    } catch (error) {}
  };

  const onClickSaveHandler = async () => {
    const { companyName, companyId, ...projectToBeEdited } = editFormParams;
    setIsChanging(true);

    try {
      const response = await apiService.editProject(projectToBeEdited);

      if (response.isSuccess) {
        enqueueSnackbar(
          <FormattedMessage id="dashboard.updateSuccess" defaultMessage="Proje başarılı bir şekilde güncellendi." />,
          {
            variant: 'success',
          }
        );

        onClickCancelHandler();
      }
    } catch (error) {
    } finally {
      setIsChanging(false);
    }
  };

  const onClickApproveDeleteHandler = async () => {
    setIsDeleting(true);

    try {
      const response = await apiService.deleteProject(selectedProject.id);

      if (response.isSuccess) {
        enqueueSnackbar(
          <FormattedMessage id="dashboard.deleteSuccess" defaultMessage="Proje başarılı bir şekilde silindi." />,
          {
            variant: 'success',
          }
        );

        getProjects();
      }
    } catch (error) {
    } finally {
      onClickCancelHandler();
    }
  };

  // sync helper functions
  const handleProjectSelect = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    if (!value) return setSelectedCompanyId(null);

    const selectedProject = projects.find((project) => project.title === value);
    selectedProject && setSelectedCompanyId(selectedProject.id);
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setEditFormParams((prevState) => ({ ...prevState, [event.target.id]: event.target.value }));

  const onChangeHandlerForAll = edit ? onChangeHandler : () => {};
  const onChangeHandlerForCreater =
    edit && loggedUser.role === ROLE_TYPE_ENUM.CREATER
      ? onChangeHandler
      : edit && loggedUser.role === ROLE_TYPE_ENUM.WRITER
      ? () =>
          enqueueSnackbar(
            <FormattedMessage
              id="dashboard.noPermission"
              defaultMessage="Bu alanı düzenleme yetkiniz bulunmamaktadır!"
            />,
            {
              variant: 'error',
            }
          )
      : () => {};

  const onPressTabKeyHandler = (e: any) => {
    const { value } = e.target;

    if (e.key === 'Tab') {
      e.preventDefault();

      const cursorPosition = e.target.selectionStart;
      const cursorEndPosition = e.target.selectionEnd;
      const tab = '\t';

      const lastValue = value.substring(0, cursorPosition) + tab + value.substring(cursorEndPosition);

      e.target.value = lastValue;
      setEditFormParams((prevState) => ({
        ...prevState,
        [e.target.id]: lastValue,
      }));

      // if you modify the value programmatically, the cursor is moved
      // to the end of the value, we need to reset it to the correct
      // position again
      e.target.selectionStart = cursorPosition + 1;
      e.target.selectionEnd = cursorPosition + 1;
    }
  };

  const onPressTabKeyHandlerForAll = edit ? onPressTabKeyHandler : () => {};
  const onPressTabKeyHandlerForCreater =
    edit && loggedUser.role === ROLE_TYPE_ENUM.CREATER
      ? onPressTabKeyHandler
      : edit && loggedUser.role === ROLE_TYPE_ENUM.WRITER
      ? () =>
          enqueueSnackbar(
            <FormattedMessage
              id="dashboard.noPermission"
              defaultMessage="Bu alanı düzenleme yetkiniz bulunmamaktadır!"
            />,
            {
              variant: 'error',
            }
          )
      : () => {};

  const onClickEditHandler = () => {
    if (!selectedCompanyId) {
      enqueueSnackbar(<FormattedMessage id="dashboard.noSelection" defaultMessage="Lütfen en az 1 proje seçiniz!" />, {
        variant: 'error',
      });
      return;
    }

    setEdit(true);
  };

  const onClickDeleteHandler = () => {
    if (!selectedCompanyId) {
      enqueueSnackbar(<FormattedMessage id="dashboard.noSelection" defaultMessage="Lütfen en az 1 proje seçiniz!" />, {
        variant: 'error',
      });
      return;
    }

    setDeleteMode(true);
    setDeleteModalOpen(true);
  };

  const onClickCancelHandler = () => {
    setEdit(false);
    setDeleteMode(false);
    setIsDeleting(false);
    setDeleteModalOpen(false);
    setSelectedCompanyId(null);
  };

  // useEffect hooks
  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    if (!selectedCompanyId) {
      setSelectedProject(initialState);
      setEditFormParams(initialState);
      return;
    }

    getSpecificProject(selectedCompanyId);
  }, [selectedCompanyId]);

  return (
    <Grid container direction="column">
      <Grid justifyContent="center" container item mb={2}>
        {edit ? (
          <>
            <Grid item xs></Grid>
            <Grid item xs={1}>
              <Button onClick={onClickSaveHandler} fullWidth disabled={isChanging} variant="contained" color="success">
                <FormattedMessage id="dashboard.save" defaultMessage="Kaydet" />
              </Button>
            </Grid>
            <Grid item xs={1} ml={2}>
              <Button onClick={onClickCancelHandler} fullWidth disabled={isChanging} variant="contained" color="error">
                <FormattedMessage id="dashboard.cancel" defaultMessage="Vazgeç" />
              </Button>
            </Grid>
          </>
        ) : deleteMode ? (
          <>
            <Grid item xs></Grid>
            <Grid item xs={1}>
              <Button onClick={onClickEditHandler} fullWidth variant="contained">
                <FormattedMessage id="dashboard.edit" defaultMessage="Düzenle" />
              </Button>
            </Grid>
            <Grid item xs={1} ml={2}>
              <Button onClick={onClickDeleteHandler} fullWidth variant="contained" color="secondary">
                <FormattedMessage id="dashboard.delete" defaultMessage="Sil" />
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3}>
              <Autocomplete
                disablePortal
                id="combo-box-projects"
                size="small"
                sx={{ minWidth: 250 }}
                options={projects.map((project) => project.title)}
                onChange={handleProjectSelect}
                loading={loadingProjects}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={<FormattedMessage id="dashboard.projects" defaultMessage="Projeler" />}
                    name="projectCompanyName"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingProjects ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs></Grid>
            {(loggedUser.role === ROLE_TYPE_ENUM.CREATER || loggedUser.role === ROLE_TYPE_ENUM.WRITER) && (
              <Grid item xs={1}>
                <Button onClick={onClickEditHandler} fullWidth variant="contained">
                  <FormattedMessage id="dashboard.edit" defaultMessage="Düzenle" />
                </Button>
              </Grid>
            )}
            {loggedUser.role === ROLE_TYPE_ENUM.CREATER && (
              <Grid item xs={1} ml={2}>
                <Button onClick={onClickDeleteHandler} fullWidth variant="contained" color="secondary">
                  <FormattedMessage id="dashboard.delete" defaultMessage="Sil" />
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Divider sx={{ marginY: 2 }} />
      <Grid container direction="row" gap={2}>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.companyName" defaultMessage="Firma" />}
          </InputLabel>
          <TextField
            fullWidth
            size="small"
            onChange={
              edit
                ? () =>
                    enqueueSnackbar(
                      <FormattedMessage id="dashboard.noEdit" defaultMessage="Bu alan düzenlemeye açık değildir!" />,
                      { variant: 'warning' }
                    )
                : () => {}
            }
            onKeyDown={
              edit
                ? (e: any) => {
                    if (e.key === 'Tab') {
                      e.preventDefault();

                      enqueueSnackbar(
                        <FormattedMessage id="dashboard.noEdit" defaultMessage="Bu alan düzenlemeye açık değildir!" />,
                        { variant: 'warning' }
                      );
                    }
                  }
                : () => {}
            }
            value={selectedProject.companyName || ''}
            id="companyName"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.products" defaultMessage="Ürünler" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForCreater}
            onKeyDown={onPressTabKeyHandlerForCreater}
            value={(edit ? editFormParams.project : selectedProject.project) || ''}
            id="project"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.connectionTypes" defaultMessage="Bağlantı Türleri" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForCreater}
            onKeyDown={onPressTabKeyHandlerForCreater}
            value={(edit ? editFormParams.connectType : selectedProject.connectType) || ''}
            id="connectType"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.gibMethod" defaultMessage="GİB Yöntemi" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForCreater}
            onKeyDown={onPressTabKeyHandlerForCreater}
            value={(edit ? editFormParams.gibType : selectedProject.gibType) || ''}
            id="gibType"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.banks" defaultMessage="Kullanılan Bankalar" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForCreater}
            onKeyDown={onPressTabKeyHandlerForCreater}
            value={(edit ? editFormParams.usedBanks : selectedProject.usedBanks) || ''}
            id="usedBanks"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.projectLiveDate" defaultMessage="Proje Canlı Geçiş" />}
          </InputLabel>
          {edit ? (
            <DatePicker
              localeText={{
                previousMonth: intl.formatMessage({ id: 'dashboard.previousMonth', defaultMessage: 'Önceki Ay' }),
                nextMonth: intl.formatMessage({ id: 'dashboard.nextMonth', defaultMessage: 'Sonraki Ay' }),

                // for Mobile
                okButtonLabel: intl.formatMessage({ id: 'dashboard.okButtonLabel', defaultMessage: 'Tamam' }),
                cancelButtonLabel: intl.formatMessage({
                  id: 'dashboard.cancelButtonLabel',
                  defaultMessage: 'Vazgeç',
                }),
              }}
              value={editFormParams.liveDate !== null ? dayjs(editFormParams.liveDate) : null}
              onChange={(newValue) =>
                setEditFormParams((prevState) => ({
                  ...prevState,
                  liveDate: newValue ? (newValue.isValid() ? newValue.toISOString() : null) : null,
                }))
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  variant: 'outlined',
                },
              }}
            />
          ) : (
            <TextField
              fullWidth
              size="small"
              value={selectedProject.liveDate !== null ? renderFormattedDate(selectedProject.liveDate) : ''}
              id="liveDate"
              variant="outlined"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.archiveLiveDate" defaultMessage="Arşiv Canlı Geçiş" />}
          </InputLabel>
          {edit ? (
            <DatePicker
              localeText={{
                previousMonth: intl.formatMessage({ id: 'dashboard.previousMonth', defaultMessage: 'Önceki Ay' }),
                nextMonth: intl.formatMessage({ id: 'dashboard.nextMonth', defaultMessage: 'Sonraki Ay' }),

                // for Mobile
                okButtonLabel: intl.formatMessage({ id: 'dashboard.okButtonLabel', defaultMessage: 'Tamam' }),
                cancelButtonLabel: intl.formatMessage({
                  id: 'dashboard.cancelButtonLabel',
                  defaultMessage: 'Vazgeç',
                }),
              }}
              value={editFormParams.archiveLiveDate !== null ? dayjs(editFormParams.archiveLiveDate) : null}
              onChange={(newValue) =>
                setEditFormParams((prevState) => ({
                  ...prevState,
                  archiveLiveDate: newValue ? (newValue.isValid() ? newValue.toISOString() : null) : null,
                }))
              }
              slotProps={{
                textField: {
                  fullWidth: true,
                  size: 'small',
                  variant: 'outlined',
                },
              }}
            />
          ) : (
            <TextField
              fullWidth
              size="small"
              value={
                selectedProject.archiveLiveDate !== null ? renderFormattedDate(selectedProject.archiveLiveDate) : ''
              }
              id="archiveLiveDate"
              variant="outlined"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.companyCode" defaultMessage="Şirket Kodu" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.companyCode : selectedProject.companyCode) || ''}
            id="companyCode"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.vkn" defaultMessage="VKN" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.vkn : selectedProject.vkn) || ''}
            id="vkn"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.apiPassword" defaultMessage="API Şifresi" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.apiInformation : selectedProject.apiInformation) || ''}
            id="apiInformation"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.serviceApiInformations" defaultMessage="Sunucu API Bilgileri" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.serviceApiInformation : selectedProject.serviceApiInformation) || ''}
            id="serviceApiInformation"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.RFCUserInformations" defaultMessage="RFC Kullanıcı Bilgileri" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.rfcUserInformation : selectedProject.rfcUserInformation) || ''}
            id="rfcUserInformation"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.vpnInformations" defaultMessage="VPN Bilgileri" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.vpnInformation : selectedProject.vpnInformation) || ''}
            id="vpnInformation"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {
              <FormattedMessage
                id="dashboard.serverConnectionInformations"
                defaultMessage="Sunucu Bağlantı Bilgileri"
              />
            }
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={
              (edit ? editFormParams.serverConnectionInformation : selectedProject.serverConnectionInformation) || ''
            }
            id="serverConnectionInformation"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.serverExternalIPInformation" defaultMessage="Sunucu Dış IP Bilgisi" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.serverExternalIp : selectedProject.serverExternalIp) || ''}
            id="serverExternalIp"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.financialSeal" defaultMessage="Mali Mühür" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.financialSeal : selectedProject.financialSeal) || ''}
            id="financialSeal"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.SAPDev" defaultMessage="SAP Dev" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.sapDev : selectedProject.sapDev) || ''}
            id="sapDev"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.SAPQA" defaultMessage="SAP QA" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.sapTest : selectedProject.sapTest) || ''}
            id="sapTest"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.SAPLive" defaultMessage="SAP Canlı" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.sapProd : selectedProject.sapProd) || ''}
            id="sapProd"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.todos" defaultMessage="Yapılacaklar" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.todo : selectedProject.todo) || ''}
            id="todo"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.liveRequest" defaultMessage="Canlı Talep" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.prodRequest : selectedProject.prodRequest) || ''}
            id="prodRequest"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {
              <FormattedMessage
                id="dashboard.companiesTablePasswordsEHO"
                defaultMessage="Şirketler Tablosu Şifreleri ( EHO )"
              />
            }
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.companyTablePassword : selectedProject.companyTablePassword) || ''}
            id="companyTablePassword"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.apiVersionEHO" defaultMessage="API Versiyonu ( EHO )" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.apiVersion : selectedProject.apiVersion) || ''}
            id="apiVersion"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel sx={{ marginBottom: '5px' }}>
            {<FormattedMessage id="dashboard.newDespatchPackage" defaultMessage="Yeni İrsaliye Paketi" />}
          </InputLabel>
          <TextField
            fullWidth
            multiline
            size="small"
            onChange={onChangeHandlerForAll}
            onKeyDown={onPressTabKeyHandlerForAll}
            value={(edit ? editFormParams.newDespatchPackage : selectedProject.newDespatchPackage) || ''}
            id="newDespatchPackage"
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Dialog
        open={deleteModalOpen}
        onClose={onClickCancelHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id="dashboard.areYouSure" defaultMessage="Projeyi Silmek İstediğinize Emin Misiniz?" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage
              id="dashboard.confirmDelete"
              defaultMessage="Bu işlem onaylandıktan sonra proje veritabanından silinecektir. Silme işlemini onaylıyor musunuz?"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={isDeleting} onClick={onClickCancelHandler}>
            <FormattedMessage id="dashboard.cancel" defaultMessage="Vazgeç" />
          </Button>
          <Button variant="contained" disabled={isDeleting} color="error" onClick={onClickApproveDeleteHandler}>
            <FormattedMessage id="dashboard.delete" defaultMessage="Sil" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Dashboard;
