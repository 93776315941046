import { LocalStorage } from 'utils';
import { STORAGE_KEY_ENUM } from 'enums';
import { CssBaseline } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';
import { ErrorBoundaryLink, ErrorBoundaryError, ErrorBoundaryButton, ErrorBoundaryContainer } from './styles';

const language = LocalStorage.getItem(STORAGE_KEY_ENUM.LANGUAGE) || 'tr';

type BoundaryProps = {
  children?: ReactNode;
};

type State = {
  hasError: boolean;
  error: Error | null;
  showDetailedErrors: boolean;
  errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<BoundaryProps, State> {
  constructor(props: BoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      showDetailedErrors: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  handleDetailedErrors() {
    this.setState({ showDetailedErrors: !this.state.showDetailedErrors });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <CssBaseline />
          <ErrorBoundaryContainer>
            <h3>{language === 'tr' ? 'Üzgünüm, bir şeyler yanlış gitti.' : 'Sorry, something went wrong.'}</h3>
            <ErrorBoundaryLink href="/">
              {language === 'tr' ? 'Ana sayfaya gidin.' : 'Go to the home page.'}
            </ErrorBoundaryLink>
            <ErrorBoundaryButton
              onClick={() => {
                this.handleDetailedErrors();
              }}>
              {this.state.showDetailedErrors
                ? language === 'tr'
                  ? 'Ayrıntıları gizle'
                  : 'Hide details'
                : language === 'tr'
                ? 'Ayrıntıları göster'
                : 'Show details'}
            </ErrorBoundaryButton>
            {this.state.showDetailedErrors && (
              <>
                <ErrorBoundaryError>{this.state.error?.message}</ErrorBoundaryError>
                <p>{this.state.errorInfo?.componentStack}</p>
              </>
            )}
          </ErrorBoundaryContainer>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
