import { Link, Typography } from '@mui/material';

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 5 }}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.idecon.com.tr/">
        {'Idecon Danışmanlık Hizmetleri A.Ş.'}
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
