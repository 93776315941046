import { FormattedMessage } from 'react-intl';
import { FormEvent, useCallback } from 'react';
import Container from '@mui/material/Container';
import { useThemeContext } from 'providers/theme';
import logoSrc from 'assets/idecon-header-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Copyright, LanguagePicker, ThemeSwitcher } from 'components/@common';
import { Box, Grid, Avatar, Button, TextField, Typography, Paper } from '@mui/material';

const SignUp = () => {
  const navigate = useNavigate();
  const { mode, toggleColorMode } = useThemeContext();
  const onClickLogoHandler = useCallback(() => navigate('login'), []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
      }}>
      <Container sx={{ pt: 3 }} component="main" maxWidth="sm">
        <Paper>
          <Paper elevation={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px 20px',
              }}>
              <img style={{ cursor: 'pointer' }} onClick={onClickLogoHandler} width="120px" src={logoSrc} alt="logo" />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                }}>
                <ThemeSwitcher checked={mode === 'dark'} onChange={toggleColorMode} />
                <LanguagePicker />
              </Box>
            </Box>
          </Paper>
          <Box
            sx={{
              padding: '20px 30px',
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography sx={{ fontWeight: 'bold' }} component="h1" variant="h5">
              <FormattedMessage id="signUp.signUp" defaultMessage="Kaydol" />
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    fullWidth
                    id="firstName"
                    label={<FormattedMessage id="signUp.name" defaultMessage="Ad" />}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    id="lastName"
                    label={<FormattedMessage id="signUp.surname" defaultMessage="Soyad" />}
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="userName"
                    label={<FormattedMessage id="signUp.userName" defaultMessage="Kullanıcı Adı" />}
                    name="userName"
                    autoComplete="userName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="password"
                    label={<FormattedMessage id="signUp.password" defaultMessage="Parola" />}
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </Grid>
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                <FormattedMessage id="signUp.signUp" defaultMessage="Kaydol" />
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <FormattedMessage
                      id="signUp.alreadyHaveAnAccount"
                      defaultMessage="Zaten hesabınız var mı? Giriş Yapın"
                    />
                  </Link>
                </Grid>
              </Grid>
            </Box>
            <Copyright />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUp;
