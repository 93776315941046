import {
  Box,
  Grid,
  Paper,
  Avatar,
  Button,
  Backdrop,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import apiService from 'library/api-service';
import { useThemeContext } from 'providers/theme';
import { TwoFactorAuthCodes } from 'library/types';
import logoSrc from 'assets/idecon-header-logo.png';
import { Authentication, LocalStorage } from 'utils';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import Visibility from '@mui/icons-material/Visibility';
import { ROLE_TYPE_ENUM, STORAGE_KEY_ENUM } from 'enums';
import loaderLogoSrc from 'assets/idecon-loader-logo.png';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { Copyright, LanguagePicker, ThemeSwitcher } from 'components/@common';

const initialState = {
  'field-0': '',
  'field-1': '',
  'field-2': '',
  'field-3': '',
  'field-4': '',
  'field-5': '',
};

export type LoggedUser = {
  fullName: string;
  role: ROLE_TYPE_ENUM;
};

export type LoginFormParams = {
  email: string;
  password: string;
};

const Login = () => {
  // other hooks
  const intl = useIntl();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { mode, toggleColorMode } = useThemeContext();
  const onClickLogoHandler = useCallback(() => navigate('login'), []);

  // useState hooks
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState(initialState);
  const [showPassword, setShowPassword] = useState(false);
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const [twoFactorAuthError, setTwoFactorAuthError] = useState(false);
  const [twoFactorAuthCodes, setTwoFactorAuthCodes] = useState<TwoFactorAuthCodes | null>(null);

  // form validations & form state
  const schema = yup.object().shape({
    email: yup
      .string()
      .email(intl.formatMessage({ id: 'login.invalidEmail', defaultMessage: 'Geçersiz e-posta formatı' }))
      .required(intl.formatMessage({ id: 'general.required', defaultMessage: 'Bu alan boş bırakılamaz' })),
    password: yup
      .string()
      .required(intl.formatMessage({ id: 'general.required', defaultMessage: 'Bu alan boş bırakılamaz' })),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<LoginFormParams>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  // async helper functions
  const login = async () => {
    const code = Object.values(codes).join('');

    // Numeric control & Empty field control
    if (Number.isNaN(Number(code)) || code.length !== 6) {
      setTwoFactorAuthError(true);
      enqueueSnackbar(
        <FormattedMessage
          id="login.invalid2FACode"
          defaultMessage="Lütfen rakamlardan oluşan 6 haneli kodu giriniz!"
        />,
        {
          variant: 'error',
        }
      );
      return;
    } else {
      setTwoFactorAuthError(false);
    }

    // If the checks have been passed successfully...
    setLoading(true);

    try {
      const response = await apiService.signOn({ email, password, code });

      if (response.isSuccess) {
        LocalStorage.setItem(
          STORAGE_KEY_ENUM.LOGGED_USER,
          JSON.stringify({
            fullName: response.data.nameSurname,
            role: response.data.role,
          })
        );
        Authentication.setToken(response.data.token);

        setTimeout(() => {
          window.location.href = '/';
        }, 500);
      }
    } catch (err: any) {
      if (err.response.data.Message === 'Doğrulama kodu hatalı!') return setCodes(initialState);

      handleReset();
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: LoginFormParams) => {
    setLoading(true);

    try {
      const response = await apiService.getAuthenticator(data.email);

      if (response.isSuccess) {
        setTwoFactorAuthCodes(response.data);
        setShowTwoFactorAuth(true);
        setEmail(data.email);
        setPassword(data.password);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  // sync helper functions
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value, name } = e.target;
    const currentFieldIndex = +name.split('-')[1];

    // If the value is deleted and the input sequence is not 0, it should go to the previous input.
    if (!value) {
      setCodes({
        ...codes,
        [name]: value,
      });

      const nextSibling: HTMLElement | null = document.querySelector(
        `input[name=field-${currentFieldIndex > 0 ? currentFieldIndex - 1 : 0}]`
      );

      if (nextSibling) {
        nextSibling.focus();
      }
      return;
    }

    // If value comes, go to the next input
    setCodes({
      ...codes,
      [name]: value,
    });

    const nextSibling: HTMLElement | null = document.querySelector(`input[name=field-${currentFieldIndex + 1}]`);

    if (nextSibling) {
      nextSibling.focus();
    }
  };

  const handleReset = () => {
    setShowTwoFactorAuth(false);
    setCodes(initialState);
    reset();
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://images.unsplash.com/photo-1586892478025-2b5472316f22?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Paper elevation={3}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px 20px',
            }}>
            <img style={{ cursor: 'pointer' }} onClick={onClickLogoHandler} width="120px" src={logoSrc} alt="logo" />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}>
              <ThemeSwitcher checked={mode === 'dark'} onChange={toggleColorMode} />
              <LanguagePicker />
            </Box>
          </Box>
        </Paper>
        <Box
          sx={{
            my: showTwoFactorAuth ? 4 : 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {showTwoFactorAuth && twoFactorAuthCodes ? (
            <>
              <Typography variant="h4">
                <FormattedMessage id="login.2FA" defaultMessage="İki Adımlı Doğrulama" />
              </Typography>
              <Typography paragraph sx={{ mt: 1 }}>
                <FormattedMessage
                  id="login.openAppMessage"
                  defaultMessage="Lütfen mobil cihazınızda Google Authenticator uygulamasını veya Google Chrome eklentisini açın ve altı haneli kodu almak için QR kodunu tarayın."
                />
              </Typography>
              <embed style={{ width: '40%' }} type="image/png" src={twoFactorAuthCodes.qrCodeSetupImageUrl} />
              <Typography>
                <FormattedMessage
                  id="login.manuelKeyMessage"
                  defaultMessage="Uygulamanız QR kodunu tanımıyorsa aşağıda verilen kodu manuel olarak girin:"
                />
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{twoFactorAuthCodes.manualEntryKey}</Typography>
              <Box
                sx={{
                  marginTop: '5%',
                  marginBottom: '5%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                {['', '', '', '', '', ''].map((_, index) => {
                  return (
                    <TextField
                      sx={{
                        marginRight: '8px',
                        width: '56px',
                        height: '56px',
                      }}
                      autoFocus={index === 0}
                      key={`field-${index}`}
                      name={`field-${index}`}
                      value={codes[`field-${index}` as keyof typeof codes]}
                      onChange={handleChange}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') return login();
                      }}
                      inputProps={{ maxLength: 1 }}
                      error={twoFactorAuthError}
                    />
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  gap: 2,
                }}>
                <Button
                  sx={{
                    display: 'flex',
                    width: '25%',
                  }}
                  onClick={handleReset}
                  fullWidth
                  disabled={loading}
                  variant="outlined"
                  color="error">
                  <FormattedMessage id="dashboard.cancel" defaultMessage="Vazgeç" />
                </Button>
                <Button
                  sx={{
                    display: 'flex',
                    width: '100%',
                  }}
                  onClick={login}
                  fullWidth
                  variant="contained">
                  <FormattedMessage id="login.logIn" defaultMessage="Giriş Yap" />
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography sx={{ fontWeight: 'bold' }} component="h1" variant="h5">
                <FormattedMessage id="login.welcome" defaultMessage="Merhaba, Hoşgeldin!" />
              </Typography>
              <Typography component="h5">
                <FormattedMessage
                  id="login.enterInformation"
                  defaultMessage="Devam etmek için lütfen kimlik bilgilerinizi girin"
                />
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={<FormattedMessage id="login.email" defaultMessage="E-Posta" />}
                      margin="normal"
                      fullWidth
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      autoFocus
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      label={<FormattedMessage id="login.password" defaultMessage="Parola" />}
                      margin="normal"
                      fullWidth
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  <FormattedMessage id="login.logIn" defaultMessage="Giriş Yap" />
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link to="/sign-up" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <FormattedMessage id="login.signUp" defaultMessage="Hesabınız yok mu? Kaydolun" />
                    </Link>
                  </Grid>
                </Grid>
                <Copyright />
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Backdrop
        sx={{ display: 'flex', flexDirection: 'column', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <img src={loaderLogoSrc} alt="loader-logo" />
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default Login;
