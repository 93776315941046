import {
  ThemeProvider,
  ErrorBoundary,
  VersionProvider,
  LanguageProvider,
  SnackBarProvider,
  LocalizationProvider,
} from 'providers';
import App from './App';
import store from 'store/store';
import { StrictMode } from 'react';
import { reportWebVitals } from 'utils';
import { CssBaseline } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';

const rootElement = document.getElementById('app-root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ErrorBoundary>
      <VersionProvider>
        <LanguageProvider>
          <ThemeProvider>
            <SnackBarProvider>
              <ReduxProvider store={store}>
                <LocalizationProvider>
                  <CssBaseline />
                  <App />
                </LocalizationProvider>
              </ReduxProvider>
            </SnackBarProvider>
          </ThemeProvider>
        </LanguageProvider>
      </VersionProvider>
    </ErrorBoundary>
  </StrictMode>
);

reportWebVitals();
