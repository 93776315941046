import { Authentication } from 'utils';
import { Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { sideBarItems } from 'components/layout/sidebar';
import { useLocation, useNavigate } from 'react-router-dom';

const Users = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const loggedUser = Authentication.getLoggedUser();
  const goToMainPage = useCallback(() => navigate('/'), []);

  useEffect(() => {
    const sidebarItem = sideBarItems.find((sidebarItem) => sidebarItem.route === pathname.slice(1));
    const hasPermission = sidebarItem?.permissions.some((permission) => permission === loggedUser.role);

    if (!hasPermission) return goToMainPage();
  }, []);

  return <Typography paragraph>Users Page</Typography>;
};

export default Users;
