import { LocalStorage } from 'utils';
import * as Languages from 'languages';
import { STORAGE_KEY_ENUM } from 'enums';
import { IntlProvider } from 'react-intl';
import { createContext, useEffect, useState, ReactNode, useContext } from 'react';

const defaultLanguage = 'tr';

type LanguageContextProps = {
  language: string;
  changeLanguage: (newLanguage: string) => void;
};

export const LanguageContext = createContext<LanguageContextProps>({
  language: defaultLanguage,
  changeLanguage: () => {},
});

type LanguageProviderProps = {
  children: ReactNode;
};

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState(defaultLanguage);

  const getDefaultLanguages = () => {
    const langFromStorage = LocalStorage.getItem(STORAGE_KEY_ENUM.LANGUAGE);
    return langFromStorage ? langFromStorage : defaultLanguage;
  };

  const setDefaultLanguage = (language: string) => {
    LocalStorage.setItem(STORAGE_KEY_ENUM.LANGUAGE, language);
    setLanguage(language);
  };

  const changeLanguage = (newLanguage: string) => {
    if (language === newLanguage) return;

    setDefaultLanguage(newLanguage);
  };

  const init = () => {
    const language = getDefaultLanguages();
    setDefaultLanguage(language);
  };

  const value = { language, changeLanguage };

  useEffect(() => {
    init();
  }, []);

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={language} messages={Languages[language as keyof typeof Languages]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

export const useLanguageContext = () => useContext(LanguageContext);
