// import { amber } from '@mui/material/colors';

const theme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *::-webkit-scrollbar {
          width: 0.4em;
          height: 0.4em;
        }
        
        *::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        
        *::-webkit-scrollbar-thumb {
          background-color: #888;
        }
        
        *::-webkit-scrollbar-thumb:hover {
          background: #555;
        }      
      `,
    },
  },
  palette: {
    // primary: amber,
  },
};

export default theme;

// todo => customize
