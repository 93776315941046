import {
  SignOnResponse,
  GetProjectsResponse,
  EditProjectResponse,
  CreateProjectResponse,
  DeleteProjectResponse,
  GetAuthenticatorResponse,
  GetSpecificProjectResponse,
} from './types';
import { ProjectToBeCreated } from 'pages/project';
import { ProjectToBeEdited } from 'pages/dashboard';
import { HttpClient } from './http-client-interceptor';

class Service extends HttpClient {
  private static instance?: Service;

  private constructor() {
    super(`${process.env.REACT_APP_REST_URL}`);
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new Service();
    }

    return this.instance;
  }

  // Account

  public getAuthenticator = (email: string): Promise<GetAuthenticatorResponse> =>
    this.instance.get(`/Account/GetAuthenticator?Email=${email}`);

  public signOn = (data: { email: string; password: string; code: string }): Promise<SignOnResponse> =>
    this.instance.post('/Account/SignOn', data);

  public signIn = (data: { nameSurname: string; email: string; password: string }): Promise<any> =>
    this.instance.post('/Account/SignIn', data);

  // Book

  public getSpecificProject = (companyId: number): Promise<GetSpecificProjectResponse> =>
    this.instance.get(`/Book/${companyId}`);

  public createProject = (project: ProjectToBeCreated): Promise<CreateProjectResponse> =>
    this.instance.post('/Book/Add', project);

  public editProject = (project: ProjectToBeEdited): Promise<EditProjectResponse> =>
    this.instance.post('/Book/Update', project);

  public deleteProject = (id: number): Promise<DeleteProjectResponse> => this.instance.post('/Book/Delete', { id });

  // Company

  public getProjects = (): Promise<GetProjectsResponse> => this.instance.get('/Company');
}

export default Service.getInstance();
