import { STORAGE_KEY_ENUM } from 'enums';
import { ReactNode, useEffect } from 'react';

type VersionProviderProps = {
  children: ReactNode;
};

const VersionProvider = ({ children }: VersionProviderProps) => {
  const currentVersion = process.env.REACT_APP_ENV_VERSION;

  useEffect(() => {
    const appVersion = localStorage.getItem(STORAGE_KEY_ENUM.VERSION);

    if (currentVersion && appVersion !== currentVersion) {
      localStorage.removeItem(STORAGE_KEY_ENUM.VERSION);
      localStorage.setItem(STORAGE_KEY_ENUM.VERSION, currentVersion);

      setTimeout(() => {
        window.location.href = '/';
      }, 100);
    }
  }, []);

  return <>{children}</>;
};

export default VersionProvider;
