import { STORAGE_KEY_ENUM } from 'enums';
import { LoggedUser } from 'pages/login';
import { LocalStorage, Token } from 'utils';

class Authentication {
  private token: string | null;

  constructor() {
    this.token = this.getToken();

    if (this.token && Token.isExpired(this.token)) {
      this.clearCache();
    }
  }

  getToken() {
    return LocalStorage.getItem(STORAGE_KEY_ENUM.TOKEN);
  }

  setToken(token: string) {
    LocalStorage.setItem(STORAGE_KEY_ENUM.TOKEN, token);
  }

  hasSession() {
    return Boolean(this.getToken());
  }

  getLoggedUser(): LoggedUser {
    return JSON.parse(LocalStorage.getItem(STORAGE_KEY_ENUM.LOGGED_USER) as string);
  }

  logOut() {
    this.clearCache();

    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  }

  clearCache() {
    LocalStorage.removeItem(STORAGE_KEY_ENUM.LOGGED_USER);
    LocalStorage.removeItem(STORAGE_KEY_ENUM.TOKEN);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Authentication();
