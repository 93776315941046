import { useState, MouseEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLanguageContext } from 'providers/language';
import { LANGUAGE_NAME_ENUM, getLanguageFlagSrc } from 'enums';
import { Menu, Tooltip, MenuItem, IconButton } from '@mui/material';

const LanguagePicker = () => {
  const intl = useIntl();
  const { changeLanguage } = useLanguageContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const getLanguageTranslated = (language: string) =>
    ({
      [LANGUAGE_NAME_ENUM.TURKISH]: intl.formatMessage({ id: 'language.turkish' }),
      [LANGUAGE_NAME_ENUM.ENGLISH]: intl.formatMessage({ id: 'language.english' }),
    }[language]);

  const onChangeLanguageHandler = (language: string) => {
    changeLanguage(language);
    handleClose();
  };

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={<FormattedMessage id="login.selectLanguage" defaultMessage="Dil Seçiniz" />}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'language-picker' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}>
          <img width={40} src={getLanguageFlagSrc(intl.locale)} alt={getLanguageTranslated(intl.locale)} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="language-picker"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        {(Object.keys(LANGUAGE_NAME_ENUM) as (keyof typeof LANGUAGE_NAME_ENUM)[]).map((key, index) => (
          <MenuItem
            key={index.toString()}
            selected={intl.locale === LANGUAGE_NAME_ENUM[key]}
            value={LANGUAGE_NAME_ENUM[key]}
            onClick={() => onChangeLanguageHandler(LANGUAGE_NAME_ENUM[key])}>
            <img
              width={30}
              height={30}
              src={getLanguageFlagSrc(LANGUAGE_NAME_ENUM[key])}
              alt={getLanguageTranslated(LANGUAGE_NAME_ENUM[key])}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default LanguagePicker;
