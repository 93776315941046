import { FormattedMessage } from 'react-intl';

// Language
export enum LANGUAGE_NAME_ENUM {
  TURKISH = 'tr',
  ENGLISH = 'en',
}

export const getLanguageTranslated = (language: string) =>
  ({
    [LANGUAGE_NAME_ENUM.TURKISH]: <FormattedMessage id="language.turkish" defaultMessage="Türkçe" />,
    [LANGUAGE_NAME_ENUM.ENGLISH]: <FormattedMessage id="language.english" defaultMessage="İngilizce" />,
  }[language]);

export const getLanguageFlagSrc = (language: string) =>
  ({
    [LANGUAGE_NAME_ENUM.TURKISH]: 'https://flagcdn.com/tr.svg',
    [LANGUAGE_NAME_ENUM.ENGLISH]: 'https://flagcdn.com/gb.svg',
  }[language]);

// Role
export enum ROLE_API_NAME_ENUM {
  CREATER = 'Creater',
  WRITER = 'Writer',
  VIEWER = 'Viewer',
  APPROVER = 'Approver',
}

export enum ROLE_TYPE_ENUM {
  CREATER = 0,
  WRITER = 1,
  VIEWER = 2,
  APPROVER = 3,
}

export const getRoleTranslated = (role: number) =>
  ({
    [ROLE_TYPE_ENUM.CREATER]: <FormattedMessage id="role.creater" defaultMessage="Oluşturucu" />,
    [ROLE_TYPE_ENUM.WRITER]: <FormattedMessage id="role.writer" defaultMessage="Yazıcı" />,
    [ROLE_TYPE_ENUM.VIEWER]: <FormattedMessage id="role.viewer" defaultMessage="Görüntüleyici" />,
    [ROLE_TYPE_ENUM.APPROVER]: <FormattedMessage id="role.approver" defaultMessage="Onaylayıcı" />,
  }[role]);

// Storage Keys
export enum STORAGE_KEY_ENUM {
  TOKEN = 'token',
  LANGUAGE = 'language',
  VERSION = 'app-version',
  THEME_MODE = 'theme-mode',
  LOGGED_USER = 'logged-user',
}
