import dayjs from 'dayjs';
import { LocalStorage } from 'utils';
import { STORAGE_KEY_ENUM } from 'enums';

const renderFormattedDate = (date: string) => {
  const language = LocalStorage.getItem(STORAGE_KEY_ENUM.LANGUAGE) || 'tr';

  return dayjs(date).format(language === 'tr' ? 'DD.MM.YYYY' : 'MM/DD/YYYY');
};

export default renderFormattedDate;
