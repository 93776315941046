import { LocalStorage } from 'utils';
import { STORAGE_KEY_ENUM } from 'enums';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        Language: `TR`,
      },
    });

    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private _initializeResponseInterceptor = async () =>
    this.instance.interceptors.response.use(this._handleResponse, this._handleError);

  private _initializeRequestInterceptor = async () => {
    this.instance.interceptors.request.use((config) => {
      const token = LocalStorage.getItem(STORAGE_KEY_ENUM.TOKEN);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers.Language = LocalStorage.getItem(STORAGE_KEY_ENUM.LANGUAGE)?.toUpperCase() || 'TR';
      }

      return config;
    });
  };

  private _handleResponse = (response: AxiosResponse) => response.data;

  protected _handleError = (error: any) => {
    if (typeof window !== 'undefined') {
      if (error?.response?.status === 401) {
        window.location.href = '/login';
      }

      if (error.response.data.Message) {
        window.dispatchEvent(new CustomEvent('errorEvent', { detail: error.response.data.Message }));
      } else {
        window.dispatchEvent(new CustomEvent('errorEvent', { detail: error?.message }));
      }
    }

    return Promise.reject(error);
  };
}
