import 'dayjs/locale/en';
import 'dayjs/locale/tr';
import { ReactNode } from 'react';
import { useLanguageContext } from 'providers/language';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as Provider } from '@mui/x-date-pickers';

type LanguageProviderProps = {
  children: ReactNode;
};

const LocalizationProvider = ({ children }: LanguageProviderProps) => {
  const { language } = useLanguageContext();

  return (
    <Provider dateAdapter={AdapterDayjs} adapterLocale={language}>
      {children}
    </Provider>
  );
};

export default LocalizationProvider;
