class LocalStorage {
  getItem(key: string) {
    return localStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    return localStorage.setItem(key, value);
  }

  removeItem(key: string) {
    return localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }

  setItemWithExpire(key: string, data: any, timestamp = new Date().getTime() + 24 * 60 * 60 * 1000) {
    const item = {
      data,
      timestamp,
    };
    this.setItem(key, JSON.stringify(item));
  }

  getItemIfNotExpired(key: string) {
    const data = this.getItem(key);
    if (!data) return undefined;

    const parsed = JSON.parse(data);

    const expirationDate = parsed.timestamp;
    const now = new Date().getTime();

    return now < expirationDate ? parsed : undefined;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LocalStorage();
