import * as Pages from 'pages';
import { ReactNode } from 'react';
import Layout from 'components/layout';
import { Authentication } from 'utils';
import { Routes, Navigate, Route, BrowserRouter } from 'react-router-dom';

const App = () => {
  const hasSession = Authentication.hasSession();
  const redirect = hasSession ? '/dashboard' : '/login';

  const renderPublicRoute = (permission: boolean, component: ReactNode) => {
    return !permission ? component : <Navigate to="/dashboard" />;
  };

  const renderPrivateRoute = (permission: boolean, component: ReactNode) => {
    return permission ? component : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {/* Public Routes */}
          <Route path="/login" element={renderPublicRoute(hasSession, <Pages.Login />)} />
          <Route path="/sign-up" element={renderPublicRoute(hasSession, <Pages.SignUp />)} />

          {/* Private Routes */}
          <Route path="/dashboard" element={renderPrivateRoute(hasSession, <Pages.Dashboard />)} />
          <Route path="/project" element={renderPrivateRoute(hasSession, <Pages.Project />)} />
          <Route path="/users" element={renderPrivateRoute(hasSession, <Pages.Users />)} />
          <Route path="/timeline" element={renderPrivateRoute(hasSession, <Pages.Timeline />)} />
          <Route path="/profile" element={renderPrivateRoute(hasSession, <Pages.Profile />)} />

          {/* Redirecting from path "/" */}
          <Route path="/*" element={<Navigate to={redirect} />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
