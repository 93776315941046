import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  background-color: #1e1e1e;
  color: rgb(237, 236, 236, 0.85);
`;

export const ErrorBoundaryLink = styled.a`
  text-decoration: none;
  font-weight: 600;
  color: rgb(237, 236, 236, 0.85);
  padding: 8px;
  border: 1px solid rgb(237, 236, 236, 0.85);
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const ErrorBoundaryButton = styled.button`
  border: none;
  background-color: rgb(237, 236, 236, 0.85);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ErrorBoundaryError = styled.h4`
  color: tomato;
`;
