import Header from './header';
import SideBar from './sidebar';
import { ReactElement } from 'react';
import { Authentication } from 'utils';
import { Box, Toolbar } from '@mui/material';

type LayoutProps = {
  children: ReactElement;
};

const Layout = ({ children }: LayoutProps) => {
  const hasSession = Authentication.hasSession();

  if (!hasSession) return children;

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
