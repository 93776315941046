import Profile from './profile';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from 'providers/theme';
import logoSrc from 'assets/idecon-header-logo.png';
import { AppBar, Box, Toolbar } from '@mui/material';
import { LanguagePicker, ThemeSwitcher } from 'components/@common';

const Header = () => {
  const navigate = useNavigate();
  const { mode, toggleColorMode } = useThemeContext();
  const onClickLogoHandler = useCallback(() => navigate('dashboard'), []);

  return (
    <AppBar color="inherit" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <Box width="100vw" display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <img style={{ cursor: 'pointer' }} onClick={onClickLogoHandler} width="120px" src={logoSrc} alt="logo" />
          </Box>
          <Box display="flex" alignItems="center" gap={2}>
            <ThemeSwitcher checked={mode === 'dark'} onChange={toggleColorMode} />
            <LanguagePicker />
            <Profile />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
